import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Avatar from 'react-avatar'
export default class ChefAvatarDiner extends Component {
  // constructor(props) {
  //   super(props);
  // }
  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { person, hideLink,style } = this.props
    let styleDeta= {...style,backgroundImage: `url(${person.avatar_url? person.avatar_url : person.avatarUrl})`}
    return (
      <React.Fragment>

        {person.avatarUrl && !person.avatarUrl.includes('/thumb_default.png') ? 
          <React.Fragment>
            <div
              className="chef-or-host__avatar"
              style={styleDeta}
            >
              {hideLink ? (
                <img
                  className="lazyload lazysizes avatar hidden"
                  alt={person.fullname}
                  src={person.avatarUrl}
                  data-src={person.avatarUrl}
                  data-widths="[180, 360, 540, 720, 900, 1080, 1296, 1512, 1728, 2048]"
                />
              ) : (
                <Link to={`/host/${person.slug}`}>
                  <img
                    className="lazyload lazysizes avatar hidden"
                    alt={person.fullname}
                    src={person.avatarUrl}
                    data-src={ person.avatarUrl}
                    data-widths="[180, 360, 540, 720, 900, 1080, 1296, 1512, 1728, 2048]"
                  />
                </Link>
              )}
            </div>
            {person.hostRating === 0 ? (
              <div
                className="chef-or-host__rating badge badge--rating"
                style={{ paddingTop: '17px' }}
              >
                <small />★ {}
               
              </div>
            ) : (
              <div className="chef-or-host__rating badge badge--rating">
                <small>
              (
                  {person.reviewsReceivedAsHostCount ||
                person.hostReviewsReceivedCount}
              )
                </small>
            ★ {person.hostRating}
                
              </div>
            )}
          </React.Fragment>:

          <div className="profileAvatarDrop chef-or-host__avatar" styel={style}>
            <Avatar
              name={person.fullname}
              color="#000000"
              size="100%"
              round
            />
          </div>
        }
        
      </React.Fragment>
    )
  }
}

ChefAvatarDiner.propTypes = {
  person: PropTypes.shape({
    fullname: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string.isRequired,
    reviewsReceivedAsHostCount: PropTypes.number.isRequired,
    hostRating: PropTypes.number.isRequired
  }).isRequired
}
