import React, { Component } from 'react'

export default class Logo extends Component {
  render() {
    return (
      <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 0H42.5C46.213 0 49.774 1.45082 52.3995 4.03328C55.025 6.61575 56.5 10.1183 56.5 13.7705C56.5 17.4227 55.025 20.9252 52.3995 23.5077C49.774 26.0902 46.213 27.541 42.5 27.541H6.1C4.61479 27.541 3.19041 26.9607 2.1402 25.9277C1.09 24.8947 0.5 23.4937 0.5 22.0328V0Z" fill="black" />
        <path d="M9.57839 10.9778C10.0923 11.0207 10.5929 11.1664 11.0515 11.4064C11.5101 11.6464 11.9178 11.976 12.2513 12.3765C12.9944 13.2732 13.3858 14.418 13.3501 15.5908C13.3874 16.7955 12.9718 17.9691 12.1881 18.8723C11.6616 19.4683 10.9701 19.8877 10.2047 20.0752C9.41099 20.2976 8.56454 20.2128 7.82849 19.8374C7.36856 19.5932 6.97288 19.2402 6.67471 18.8079L6.44122 19.7871C6.42618 19.8491 6.39072 19.9041 6.34073 19.9428C6.29075 19.9815 6.22926 20.0015 6.16651 19.9997H4.44683C4.37397 19.9997 4.3041 19.9702 4.25258 19.9177C4.20107 19.8653 4.17212 19.7941 4.17212 19.7199V6.70602C4.17212 6.63182 4.20107 6.56069 4.25258 6.50823C4.3041 6.45576 4.37397 6.42627 4.44683 6.42627H6.41099C6.48384 6.42627 6.55373 6.45576 6.60525 6.50823C6.65677 6.56069 6.6857 6.63182 6.6857 6.70602V12.3374C6.97742 11.9088 7.36669 11.5584 7.82025 11.3163C8.36292 11.036 8.97268 10.9186 9.57839 10.9778ZM8.7268 13.2354C8.4383 13.2291 8.15177 13.2852 7.88612 13.4C7.62047 13.5147 7.38173 13.6855 7.18567 13.9011C6.77271 14.3569 6.55263 14.9599 6.57307 15.5796C6.56191 15.8867 6.61026 16.193 6.71537 16.481C6.82049 16.769 6.98029 17.033 7.18567 17.2581C7.38187 17.4734 7.6207 17.6438 7.88636 17.7581C8.15202 17.8724 8.43848 17.928 8.7268 17.9212C9.01519 17.9285 9.3018 17.8731 9.56754 17.7588C9.83328 17.6445 10.072 17.4738 10.2679 17.2581C10.6834 16.8038 10.9048 16.2001 10.8833 15.5796C10.903 14.9595 10.6819 14.3564 10.2679 13.9011C10.0722 13.6851 9.8335 13.514 9.56778 13.3992C9.30205 13.2844 9.01537 13.2286 8.7268 13.2354Z" fill="white" />
        <path d="M18.4381 13.7468C18.0903 13.7415 17.7461 13.8252 17.4333 13.9914C17.1278 14.1631 16.861 14.407 16.6524 14.7052V19.9125C16.6524 19.988 16.6249 20.0603 16.5761 20.1136C16.5273 20.1669 16.4611 20.1969 16.3921 20.1969H14.5308C14.4618 20.1969 14.3956 20.1669 14.3468 20.1136C14.2979 20.0603 14.2705 19.988 14.2705 19.9125V11.4972C14.2705 11.4218 14.2979 11.3495 14.3468 11.2962C14.3956 11.2428 14.4618 11.2128 14.5308 11.2128H16.163C16.2224 11.2109 16.2807 11.2314 16.3281 11.2707C16.3754 11.3101 16.409 11.3659 16.4233 11.429L16.6602 12.4869C16.9388 12.0501 17.299 11.6828 17.717 11.4091C18.0949 11.1563 18.5286 11.0206 18.9717 11.0166C19.1154 11.0162 19.2589 11.0266 19.4013 11.0479C19.5308 11.0661 19.6577 11.1024 19.7787 11.156L19.1513 13.8748C19.0272 13.8374 18.9012 13.8079 18.7739 13.7866C18.6631 13.7636 18.5508 13.7503 18.4381 13.7468Z" fill="white" />
        <path d="M28.0379 17.4226L28.9695 18.5972C29.0131 18.6517 29.0342 18.7202 29.029 18.7891C29.0237 18.8581 28.9924 18.9226 28.941 18.9702C28.5157 19.344 28.0243 19.6392 27.4909 19.8414C26.849 20.0813 26.1666 20.2012 25.4794 20.1949C24.8492 20.2132 24.2217 20.1073 23.6343 19.8835C23.0469 19.6597 22.5117 19.3225 22.0605 18.8923C21.6134 18.4683 21.2614 17.9581 21.027 17.3941C20.7925 16.8301 20.6807 16.2246 20.6986 15.6161C20.6826 15.012 20.7906 14.4109 21.0162 13.8483C21.2417 13.2856 21.5804 12.7727 22.0121 12.34C22.4336 11.9093 22.9425 11.5692 23.5061 11.3416C24.0698 11.1139 24.6759 11.0036 25.2857 11.0178C25.8959 11.0036 26.5024 11.1138 27.0666 11.3415C27.6307 11.5691 28.1401 11.9092 28.5621 12.34C28.9938 12.7727 29.3325 13.2856 29.558 13.8483C29.7836 14.4109 29.8916 15.012 29.8755 15.6161C29.8755 15.7943 29.8755 15.9613 29.8556 16.1144C29.8357 16.2675 29.83 16.3927 29.8157 16.4595H23.1289C23.265 16.9552 23.5656 17.3928 23.9836 17.7037C24.4136 18.016 24.9381 18.1787 25.4737 18.1658C25.9004 18.1714 26.3239 18.0928 26.7188 17.9348C27.049 17.7955 27.3555 17.6078 27.6276 17.3781C27.6572 17.3538 27.6914 17.3356 27.7284 17.3247C27.7653 17.3137 27.8042 17.3102 27.8425 17.3144C27.8809 17.3186 27.918 17.3303 27.9516 17.3489C27.9852 17.3675 28.0145 17.3926 28.0379 17.4226ZM25.28 13.0553C24.8047 13.0489 24.3413 13.2011 23.9665 13.4868C23.5784 13.7867 23.2972 14.1988 23.1631 14.6642H27.3997C27.2665 14.2005 26.9886 13.789 26.6048 13.4868C26.2273 13.1981 25.7593 13.0457 25.28 13.0553Z" fill="white" />
        <path d="M38.7826 20.0007H37.0629C37.0002 20.0025 36.9387 19.9826 36.8887 19.9441C36.8387 19.9056 36.8032 19.8509 36.7882 19.7892L36.5547 18.8154C36.2565 19.2453 35.8609 19.5964 35.4009 19.8393C34.6624 20.2146 33.8123 20.298 33.0165 20.073C32.2522 19.8843 31.5619 19.4663 31.0358 18.8738C30.2552 17.9763 29.8417 16.8113 29.8793 15.6158C29.844 14.4575 30.2269 13.3259 30.9562 12.433C31.577 11.6884 32.446 11.2004 33.3983 11.0613C34.0854 10.9456 34.7911 11.0558 35.4119 11.3757C35.8676 11.6204 36.2593 11.9713 36.5547 12.3995L36.7882 11.4258C36.8027 11.3635 36.8379 11.3083 36.888 11.2693C36.938 11.2302 36.9998 11.2098 37.0629 11.2115H38.7826C38.8554 11.2115 38.9253 11.2409 38.9768 11.293C39.0283 11.3452 39.0573 11.416 39.0573 11.4897V19.7224C39.0573 19.759 39.0502 19.7951 39.0364 19.8289C39.0226 19.8626 39.0023 19.8933 38.9768 19.9192C38.9513 19.945 38.921 19.9655 38.8877 19.9795C38.8544 19.9935 38.8186 20.0007 38.7826 20.0007ZM32.3379 15.6047C32.3164 16.2218 32.5378 16.8222 32.9533 17.2741C33.1492 17.4886 33.3879 17.6583 33.6537 17.772C33.9194 17.8857 34.206 17.9408 34.4944 17.9335C34.7828 17.9406 35.0693 17.8854 35.335 17.7717C35.6007 17.6581 35.8395 17.4884 36.0355 17.2741C36.2409 17.0502 36.4007 16.7876 36.5058 16.5011C36.6109 16.2147 36.6593 15.9101 36.6481 15.6047C36.6685 14.9883 36.4485 14.3886 36.0355 13.9354C35.8397 13.7206 35.601 13.5505 35.3353 13.4364C35.0696 13.3222 34.7829 13.2666 34.4944 13.2732C34.2058 13.2664 33.9191 13.322 33.6534 13.4362C33.3877 13.5503 33.149 13.7205 32.9533 13.9354C32.5393 14.3882 32.3182 14.9879 32.3379 15.6047Z" fill="white" />
        <path d="M43.9254 16.7485H43.528V19.913C43.528 19.9882 43.4977 20.0604 43.4437 20.1136C43.3897 20.1668 43.3165 20.1968 43.2401 20.1968H41.1813C41.1049 20.1968 41.0317 20.1668 40.9777 20.1136C40.9237 20.0604 40.8933 19.9882 40.8933 19.913V6.71008C40.8933 6.63481 40.9237 6.56264 40.9777 6.50942C41.0317 6.45619 41.1049 6.42627 41.1813 6.42627H43.2401C43.3165 6.42627 43.3897 6.45619 43.4437 6.50942C43.4977 6.56264 43.528 6.63481 43.528 6.71008V14.3957H43.9254C44.4138 14.4235 44.9006 14.3195 45.3335 14.0948C45.7155 13.8242 46.0144 13.455 46.1973 13.0277L46.9748 11.3958C46.9977 11.347 47.0342 11.3056 47.0801 11.2764C47.126 11.2473 47.1793 11.2316 47.2339 11.2312H49.3302C49.3787 11.2313 49.4264 11.2436 49.4689 11.2668C49.5114 11.29 49.5472 11.3234 49.573 11.3639C49.5989 11.4043 49.614 11.4506 49.6168 11.4983C49.6196 11.5461 49.6102 11.5939 49.5893 11.6371L48.9328 12.9908C48.616 13.6266 48.331 14.1459 48.0689 14.5433C47.8531 14.8959 47.6013 15.2259 47.3174 15.5281L50.0299 19.7625C50.057 19.8053 50.0721 19.8545 50.0735 19.9049C50.075 19.9554 50.0627 20.0053 50.0381 20.0495C50.0135 20.0938 49.9773 20.1308 49.9333 20.1566C49.8894 20.1825 49.8392 20.1964 49.788 20.1968H47.4844C47.4352 20.1966 47.3869 20.184 47.344 20.1601C47.3012 20.1363 47.2652 20.102 47.2397 20.0605L45.1636 16.6548C44.9905 16.6953 44.8142 16.7209 44.6366 16.7314C44.4379 16.7428 44.2018 16.7485 43.9254 16.7485Z" fill="white" />
        <path d="M6.1 27.541H42.5C46.213 27.541 49.774 28.9918 52.3995 31.5743C55.025 34.1568 56.5 37.6593 56.5 41.3115C56.5 44.9637 55.025 48.4662 52.3995 51.0487C49.774 53.6312 46.213 55.082 42.5 55.082H0.5V33.0492C0.5 31.5883 1.09 30.1873 2.1402 29.1543C3.19041 28.1213 4.61479 27.541 6.1 27.541Z" fill="black" />
        <path d="M9.57839 38.5188C10.6156 38.6079 11.5763 39.1107 12.2513 39.9175C12.9928 40.8152 13.384 41.9593 13.3501 43.1319C13.3874 44.3365 12.9718 45.5102 12.1881 46.4133C11.6609 47.0083 10.9696 47.4276 10.2047 47.6162C9.41099 47.8386 8.56454 47.7539 7.82849 47.3784C7.36856 47.1342 6.97288 46.7812 6.67471 46.349L6.44122 47.3281C6.42618 47.3901 6.3907 47.4451 6.34072 47.4838C6.29073 47.5225 6.22926 47.5426 6.16651 47.5407H4.44683C4.37397 47.5407 4.30408 47.5112 4.25257 47.4587C4.20105 47.4063 4.17212 47.3351 4.17212 47.2609V34.247C4.17212 34.1728 4.20105 34.1017 4.25257 34.0492C4.30408 33.9968 4.37397 33.9673 4.44683 33.9673H6.41099C6.48384 33.9673 6.55373 33.9968 6.60525 34.0492C6.65677 34.1017 6.6857 34.1728 6.6857 34.247V39.8783C6.97742 39.4497 7.36669 39.0994 7.82025 38.8573C8.36311 38.5776 8.97271 38.4602 9.57839 38.5188ZM8.72405 40.7763C8.43555 40.7701 8.14902 40.8262 7.88337 40.941C7.61773 41.0558 7.37898 41.2265 7.18292 41.4422C6.76996 41.8979 6.54988 42.5009 6.57032 43.1206C6.55024 43.7362 6.77051 44.3347 7.18292 44.7851C7.37854 45.0013 7.6172 45.1725 7.88295 45.2873C8.14871 45.4021 8.43545 45.458 8.72405 45.451C9.01272 45.4585 9.29961 45.4029 9.56545 45.2881C9.83128 45.1732 10.0699 45.0018 10.2652 44.7851C10.6783 44.335 10.8996 43.7365 10.8805 43.1206C10.9002 42.5005 10.6791 41.8974 10.2652 41.4422C10.0694 41.2261 9.83075 41.055 9.56503 40.9402C9.2993 40.8254 9.01262 40.7695 8.72405 40.7763Z" fill="white" />
        <path d="M18.4381 41.3106C18.0903 41.3052 17.7461 41.389 17.4333 41.5552C17.1278 41.7269 16.861 41.9707 16.6524 42.269V47.4535C16.6524 47.529 16.6249 47.6013 16.5761 47.6546C16.5273 47.708 16.4611 47.7379 16.3921 47.7379H14.5308C14.4618 47.7379 14.3956 47.708 14.3468 47.6546C14.2979 47.6013 14.2705 47.529 14.2705 47.4535V39.0382C14.2705 38.9628 14.2979 38.8905 14.3468 38.8372C14.3956 38.7838 14.4618 38.7538 14.5308 38.7538H16.163C16.2224 38.752 16.2807 38.7724 16.3281 38.8118C16.3754 38.8511 16.409 38.9069 16.4233 38.97L16.6602 40.0251C16.9396 39.5898 17.2997 39.2236 17.717 38.9501C18.0949 38.6974 18.5286 38.5617 18.9717 38.5576C19.1154 38.5572 19.2589 38.5677 19.4013 38.5889C19.5307 38.6062 19.6575 38.6415 19.7787 38.6941L19.1513 41.4158C19.0272 41.3784 18.9012 41.3489 18.7739 41.3276C18.6626 41.3122 18.5503 41.3065 18.4381 41.3106Z" fill="white" />
        <path d="M28.0236 44.9608L28.9695 46.1382C29.0131 46.1927 29.0342 46.2611 29.029 46.3301C29.0237 46.399 28.9924 46.4636 28.941 46.5112C28.5157 46.885 28.0243 47.1802 27.4909 47.3824C26.849 47.6223 26.1666 47.7423 25.4794 47.7359C24.8492 47.7542 24.2217 47.6482 23.6343 47.4244C23.0469 47.2006 22.5117 46.8636 22.0605 46.4333C21.6134 46.0093 21.2614 45.4991 21.027 44.9351C20.7925 44.3711 20.6807 43.7656 20.6986 43.1571C20.6826 42.553 20.7906 41.9519 21.0162 41.3893C21.2417 40.8266 21.5804 40.3137 22.0121 39.881C22.4336 39.4503 22.9425 39.1102 23.5061 38.8826C24.0698 38.6549 24.6759 38.5447 25.2857 38.5588C25.8959 38.5446 26.5024 38.6549 27.0666 38.8825C27.6307 39.1102 28.1401 39.4502 28.5621 39.881C28.9938 40.3137 29.3325 40.8266 29.558 41.3893C29.7836 41.9519 29.8916 42.553 29.8755 43.1571C29.8755 43.3353 29.8755 43.5023 29.8556 43.6554C29.8357 43.8085 29.83 43.9337 29.8157 44.0005H23.1289C23.265 44.4963 23.5656 44.9339 23.9836 45.2447C24.4142 45.5558 24.9383 45.7183 25.4737 45.7068C25.9004 45.7124 26.3239 45.6339 26.7188 45.4758C27.0484 45.3355 27.3548 45.1479 27.6276 44.9191C27.6861 44.8741 27.7603 44.8533 27.8343 44.8611C27.9084 44.8689 27.9763 44.9047 28.0236 44.9608ZM25.2686 40.588C24.7931 40.5802 24.3293 40.7326 23.9551 41.0195C23.567 41.3194 23.2858 41.7315 23.1517 42.1969H27.3883C27.2551 41.7332 26.9772 41.3216 26.5934 41.0195C26.2157 40.7291 25.7462 40.5765 25.2657 40.588H25.2686Z" fill="white" />
        <path d="M38.7826 47.5417H37.0629C37.0002 47.5435 36.9387 47.5236 36.8887 47.4851C36.8387 47.4466 36.8033 47.392 36.7882 47.3302L36.5547 46.3564C36.2565 46.7863 35.8609 47.1374 35.4009 47.3803C34.6624 47.7557 33.8124 47.839 33.0165 47.614C32.2519 47.4243 31.5616 47.0054 31.0359 46.4121C30.255 45.5158 29.8413 44.3515 29.8794 43.1568C29.8436 41.9984 30.2265 40.8666 30.9562 39.9739C31.5778 39.2288 32.4477 38.7407 33.4011 38.6022C34.0873 38.4867 34.792 38.5969 35.4119 38.9166C35.8682 39.1604 36.2601 39.5115 36.5547 39.9405L36.7882 38.9667C36.8027 38.9044 36.8379 38.8492 36.888 38.8102C36.938 38.7711 36.9998 38.7507 37.0629 38.7524H38.7826C38.8554 38.7524 38.9253 38.7818 38.9768 38.8339C39.0283 38.8861 39.0573 38.9569 39.0573 39.0306V47.2635C39.0573 47.3 39.0502 47.3361 39.0364 47.3699C39.0226 47.4036 39.0023 47.4343 38.9768 47.4602C38.9513 47.486 38.921 47.5065 38.8877 47.5205C38.8544 47.5345 38.8186 47.5417 38.7826 47.5417ZM32.338 43.1679C32.3189 43.7805 32.5402 44.3757 32.9533 44.8234C33.1486 45.0388 33.3872 45.2094 33.653 45.3236C33.9189 45.4378 34.2058 45.4931 34.4944 45.4856C34.783 45.4927 35.0698 45.4373 35.3356 45.3231C35.6014 45.2089 35.84 45.0385 36.0355 44.8234C36.4479 44.3754 36.6682 43.7801 36.6481 43.1679C36.6686 42.5515 36.4485 41.9518 36.0355 41.4986C35.8397 41.2838 35.601 41.1137 35.3353 40.9996C35.0696 40.8854 34.783 40.8298 34.4944 40.8363C34.2059 40.8296 33.9192 40.8851 33.6534 40.9993C33.3877 41.1135 33.1491 41.2837 32.9533 41.4986C32.5394 41.9514 32.3183 42.5512 32.338 43.1679Z" fill="white" />
        <path d="M48.8814 47.5403H47.1545C47.0918 47.5421 47.0303 47.5221 46.9804 47.4833C46.9304 47.4446 46.895 47.3896 46.88 47.3275L46.6466 46.3476C46.35 46.7814 45.9542 47.135 45.4935 47.3779C44.7555 47.7556 43.9059 47.8395 43.1105 47.6131C42.3464 47.4222 41.6565 47.0007 41.131 46.4036C40.3526 45.5007 39.9403 44.3296 39.978 43.1279C39.9448 41.9537 40.3356 40.808 41.0761 39.9083C41.7506 39.1005 42.7108 38.5973 43.7474 38.5084C44.355 38.4528 44.9655 38.5751 45.5072 38.8612C45.961 39.1028 46.3502 39.4535 46.6411 39.883V34.2473C46.6411 34.173 46.67 34.1018 46.7215 34.0493C46.773 33.9968 46.8428 33.9673 46.9156 33.9673H48.8814C48.9542 33.9673 49.024 33.9968 49.0755 34.0493C49.127 34.1018 49.1559 34.173 49.1559 34.2473V47.2603C49.1559 47.3346 49.127 47.4058 49.0755 47.4583C49.024 47.5108 48.9542 47.5403 48.8814 47.5403ZM42.4461 43.1279C42.4271 43.7443 42.6482 44.3432 43.0611 44.7937C43.2562 45.0105 43.4947 45.1822 43.7604 45.2971C44.026 45.412 44.3128 45.4677 44.6013 45.4601C44.8898 45.4677 45.1765 45.412 45.4422 45.2971C45.7078 45.1822 45.9463 45.0105 46.1414 44.7937C46.5543 44.3432 46.7754 43.7443 46.7564 43.1279C46.7761 42.5073 46.5551 41.9037 46.1414 41.4481C45.9458 41.2319 45.7073 41.0607 45.4417 40.9458C45.1762 40.8309 44.8897 40.775 44.6013 40.7818C44.3129 40.775 44.0263 40.8309 43.7608 40.9458C43.4952 41.0607 43.2567 41.2319 43.0611 41.4481C42.6446 41.9065 42.4235 42.5147 42.4461 43.1391V43.1279Z" fill="white" />
      </svg>    )
  }
}
