import React from "react"

const SvgIcon2BecomeHost = props => (
  <svg viewBox="0 0 194.5 194.5" {...props}>
    <defs>
      <style>{`.a{fill:#000000}`}</style>
    </defs>
    <g transform="translate(-877.5 -1105.5)">
      <g transform="translate(948.227 1167.386)">
        <circle
          className="a"
          cx={2.21}
          cy={2.21}
          r={2.21}
          transform="translate(22.102 41.994)"
        />
        <circle
          className="a"
          cx={2.21}
          cy={2.21}
          r={2.21}
          transform="translate(28.733 46.415)"
        />
        <circle
          className="a"
          cx={2.21}
          cy={2.21}
          r={2.21}
          transform="translate(24.313 53.045)"
        />
        <circle
          className="a"
          cx={2.21}
          cy={2.21}
          r={2.21}
          transform="translate(30.943 59.676)"
        />
        <circle
          className="a"
          cx={2.21}
          cy={2.21}
          r={2.21}
          transform="translate(19.892 59.676)"
        />
        <path
          className="a"
          d="M51.277 51.277L37.574 25.86V11.051A5.587 5.587 0 0 0 36.469 0H16.577a5.472 5.472 0 0 0-5.526 5.526 5.9 5.9 0 0 0 4.42 5.526v15.03l-13.7 25.2A16.884 16.884 0 0 0 0 58.571V64.1a6.512 6.512 0 0 0 6.631 6.631h39.784a6.512 6.512 0 0 0 6.63-6.631v-5.529a16.884 16.884 0 0 0-1.768-7.294zM16.577 4.42h19.892a1.105 1.105 0 1 1 0 2.21h-1.105a2.088 2.088 0 0 0-2.21 2.21v17.683a1.993 1.993 0 0 0 .221 1.105l3.978 7.294a6.845 6.845 0 0 1-5.526-.221 11.272 11.272 0 0 0-10.609 0 6.845 6.845 0 0 1-5.526.221l3.978-7.294a1.992 1.992 0 0 0 .221-1.105V11.051h6.631a2.21 2.21 0 0 0 0-4.42h-9.945a1.105 1.105 0 1 1 0-2.21zM48.625 64.1a2.088 2.088 0 0 1-2.21 2.21H6.631a2.088 2.088 0 0 1-2.21-2.21v-5.529a11.77 11.77 0 0 1 1.326-5.3L13.7 38.679a10.569 10.569 0 0 0 9.946-.442 7.045 7.045 0 0 1 5.968 0 10.642 10.642 0 0 0 5.3 1.547 10.274 10.274 0 0 0 4.641-1.105l7.965 14.587a10.5 10.5 0 0 1 1.326 5.3V64.1z"
        />
      </g>
      <path
        className="a"
        d="M974.75 1300a97.25 97.25 0 1 1 97.25-97.25 97.252 97.252 0 0 1-97.25 97.25zm0-190.08a92.83 92.83 0 1 0 92.83 92.83 92.818 92.818 0 0 0-92.83-92.83z"
      />
      <circle
        cx={29.5}
        cy={29.5}
        r={29.5}
        transform="translate(1010 1109)"
        fill="#90a4ae"
      />
      <text
        transform="translate(1010 1122)"
        fill="#fff"
        fontSize={32}
        fontWeight={700}
      >
        <tspan x={20.092} y={28}>
          {`2`}
        </tspan>
      </text>
    </g>
  </svg>
)

export default SvgIcon2BecomeHost
