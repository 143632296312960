import React from "react"

const SvgIcon3BecomeHost = props => (
  <svg viewBox="0 0 196 195" {...props}>
    <defs>
      <style>{`.a{fill:#000000}`}</style>
    </defs>
    <g transform="translate(-1346 -1104.771)">
      <path
        className="a"
        d="M1436.852 1166.816a2.094 2.094 0 0 0-2.216 2.216v21.051a6.284 6.284 0 0 1-2.216 4.875 6.417 6.417 0 0 0-2.216 5.1v33.235h-4.431v-33.238a8.1 8.1 0 0 0-2.216-5.1 7.117 7.117 0 0 1-2.216-4.875v-21.048a2.216 2.216 0 0 0-4.432 0v21.051a11.6 11.6 0 0 0 3.545 8.2 2.649 2.649 0 0 1 .886 1.773v33.237a4.384 4.384 0 0 0 1.108 3.1c1.33 1.33 3.1 1.33 4.875 1.33h1.773c1.551 0 3.1 0 4.432-1.33a3.6 3.6 0 0 0 1.108-3.1v-33.238a1.981 1.981 0 0 1 .886-1.773 11.215 11.215 0 0 0 3.545-8.2v-21.05a2.094 2.094 0 0 0-2.215-2.216z"
      />
      <path
        className="a"
        d="M1427.989 1192.299a2.094 2.094 0 0 0 2.216-2.216v-21.051a2.216 2.216 0 0 0-4.432 0v21.051a2.094 2.094 0 0 0 2.216 2.216zM1464.108 1166.816a5.922 5.922 0 0 0-5.761 4.432l-7.534 25.926a8.632 8.632 0 0 0 5.761 11.08v25.04a4.445 4.445 0 0 0 4.432 4.432h4.432a4.445 4.445 0 0 0 4.427-4.433v-60.494a5.643 5.643 0 0 0-5.757-5.983zm-2.88 66.477v-27.034a2.094 2.094 0 0 0-2.216-2.216 3.907 3.907 0 0 1-3.324-1.773 3.355 3.355 0 0 1-.443-3.767l7.534-26.147a1.666 1.666 0 0 1 1.551-1.108 1.515 1.515 0 0 1 1.551 1.551l-.222 60.494z"
      />
      <path
        className="a"
        d="M1443.5 1299.771a97.5 97.5 0 1 1 97.5-97.5 97.5 97.5 0 0 1-97.5 97.5zm0-190.568a93.068 93.068 0 1 0 93.068 93.068 93.057 93.057 0 0 0-93.068-93.068z"
      />
      <circle
        cx={29.5}
        cy={29.5}
        r={29.5}
        transform="translate(1483 1109)"
        fill="#90a4ae"
      />
      <text
        transform="translate(1483 1122)"
        fill="#fff"
        fontSize={32}
        fontWeight={700}
      >
        <tspan x={19.916} y={28}>
          {`3`}
        </tspan>
      </text>
    </g>
  </svg>
)

export default SvgIcon3BecomeHost
