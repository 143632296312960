import React from "react"

const SvgIcon1BecomeHost = props => (
  <svg viewBox="0 0 195 195" {...props}>
    <defs>
      <style>{`.a{fill:#000000}`}</style>
    </defs>
    <g transform="translate(-407 -1105)">
      <path
        className="a"
        d="M522.671 1173.693a20.119 20.119 0 0 0-5.1.665 15.737 15.737 0 0 0-13.071-7.313 15.447 15.447 0 0 0-13.073 7.312 18.888 18.888 0 0 0-5.1-.665 15.513 15.513 0 0 0-5.318 29.915 2.244 2.244 0 0 1 1.33 1.994v30.137a2.094 2.094 0 0 0 2.218 2.216h39.887a2.094 2.094 0 0 0 2.213-2.216v-30.136a1.938 1.938 0 0 1 1.33-1.994 15.417 15.417 0 0 0 9.75-15.068 15.656 15.656 0 0 0-15.066-14.847zm3.545 25.7a6.377 6.377 0 0 0-3.989 6.2v27.92h-35.454v-8.854h28.807a2.216 2.216 0 0 0 0-4.432h-2.216v-11.082a2.216 2.216 0 1 0-4.432 0v11.08h-8.863v-11.08a2.216 2.216 0 0 0-4.432 0v11.08h-8.864v-14.623a6.59 6.59 0 0 0-3.989-6.2 11 11 0 0 1 3.546-21.277 10.624 10.624 0 0 1 5.1 1.108 2.427 2.427 0 0 0 1.773 0 2.269 2.269 0 0 0 1.33-1.108 11.139 11.139 0 0 1 20.386 0 9.921 9.921 0 0 1 .886 4.432 12.534 12.534 0 0 1-1.773 6.2 2.179 2.179 0 0 0 .665 3.1 3.15 3.15 0 0 0 1.33.443 2.736 2.736 0 0 0 1.773-.886 14.773 14.773 0 0 0 2.657-8.636 24.417 24.417 0 0 0-.443-3.989 14.963 14.963 0 0 1 3.1-.443 11.1 11.1 0 0 1 10.636 10.637 11.756 11.756 0 0 1-7.534 10.414z"
      />
      <path
        className="a"
        d="M504.5 1300a97.5 97.5 0 1 1 97.5-97.5 97.5 97.5 0 0 1-97.5 97.5zm0-190.568a93.068 93.068 0 1 0 93.068 93.068 93.057 93.057 0 0 0-93.068-93.068z"
      />
      <circle
        cx={29.5}
        cy={29.5}
        r={29.5}
        transform="translate(537 1109)"
        fill="#90a4ae"
      />
      <text
        transform="translate(537 1122)"
        fill="#fff"
        fontSize={32}
        fontWeight={700}
      >
        <tspan x={23.212} y={28}>
          {`1`}
        </tspan>
      </text>
    </g>
  </svg>
)

export default SvgIcon1BecomeHost
