import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Avatar from 'react-avatar'

const covidCertificate = require('../../icons/social/covidCertificate.png')

export default class ChefAvatarHost extends Component {
  // constructor(props) {
  //   super(props);
  // }
  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { person, hideLink, styleAttributes, parentComponent } = this.props

    const styleDeta = { ...styleAttributes, backgroundImage: `url(${person.avatar_url ? person.avatar_url : person.avatarUrl})` }

    return (
      <React.Fragment>
        {person.avatarUrl && !person.avatarUrl.includes('/thumb_default.png')
          ? (
            <React.Fragment>
              <div
                className={parentComponent && parentComponent === 'reviewItem' ? 'chef-or-host__avatar chef-or-host__avatar_review' : 'chef-or-host__avatar'}
                style={{ ...styleDeta, zIndex: 10 }}
              >
                {person.covidCertificate && person.covidCertificate.id ? (
                  <div
                    className="covid-badge--rating"
                    title="COVID Vaccinated"
                  >
                    <img src={covidCertificate} alt="covid vaccine cerificate" />
                  </div>
                ) : ''}
                {hideLink ? (
                  <img
                    className="lazyload lazysizes avatar hidden"
                    alt={person.fullname}
                    src={person.avatarUrl}
                    data-src={person.avatarUrl}
                    data-widths="[180, 360, 540, 720, 900, 1080, 1296, 1512, 1728, 2048]"
                  />
                ) : (
                  <Link to={`/host/${person.slug}`}>
                    <img
                      className="lazyload lazysizes avatar hidden"
                      alt={person.fullname}
                      src={person.avatarUrl}
                      data-src={person.avatarUrl}
                      data-widths="[180, 360, 540, 720, 900, 1080, 1296, 1512, 1728, 2048]"
                    />
                  </Link>
                )}
              </div>
              {person.hostRating === 0 ? (
                <div
                  className="chef-or-host__rating badge badge--rating"
                  // style={{ paddingTop: '50px' }}
                >
                  <small /><img className="logo" src={require('../../icons/logo.png')} alt="logo" /> {}
                </div>
              ) : (
                <div className="chef-or-host__rating badge badge--rating">
                  <small>
                    (
                    {person.hostRating})
                  </small>
                  ★ {10}
                </div>
              )}
            </React.Fragment>
          ) : (
            <div
              className={parentComponent && parentComponent === 'reviewItem' ? 'profileAvatarDrop chef-or-host__avatar chef-or-host__avatar_review' : 'profileAvatarDrop chef-or-host__avatar'}
              style={{ ...styleDeta }}
            >
              <Avatar
                name={person.fullname}
                color="#000000"
                size="100%"
                round
              />
            </div>
          )}
      </React.Fragment>
    )
  }
}

ChefAvatarHost.propTypes = {
  person: PropTypes.shape({
    fullname: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string.isRequired,
    reviewsReceivedAsHostCount: PropTypes.number.isRequired,
    hostRating: PropTypes.number.isRequired
  }).isRequired
}
